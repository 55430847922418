import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import gsap from 'gsap'

import bootstrapService from '../../services/BootstrapService'
import formService from '../../services/FormService'
import paymentService from '../../services/PaymentService'
import memberService from '../../services/MemberService'
import recaptchaService from '../../services/RecaptchaService'
import scheduleService from '../../services/ScheduleService'

import PrimaryButton from '../shared/Buttons/PrimaryButton/PrimaryButton'
import Input from '../shared/Input/Input'
import Toast from '../shared/Toast/Toast'

import styles from './BuyLogin.module.css'

class BuyLogin extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.container = null;
        this.form = null;
        this.toast = null;

        // State
        this.state = {
            isFormLoading: false,
            toastMessage: null,
        }

        // This Binding
        this.fillSandboxForm = this.fillSandboxForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.exitToast = this.exitToast.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupAnimations();
    }

    setupAnimations() {
        gsap.from(this.container, {
            duration: 0.5,
            opacity: 0,
        });
    }

    // Event Handlers
    fillSandboxForm(e) {
        e.preventDefault();

        if (e.ctrlKey || e.metaKey) {

            const inputs = this.form.querySelectorAll('.a4-input');

            inputs[0].querySelector('input, textarea').value = "eh1@harris5.com"
            inputs[1].querySelector('input, textarea').value = "password"
        }
    }

    submitForm(e) {
        e.preventDefault();

        if (!this.state.isFormLoading) {
            this.exitToast();

            const isFormValid = formService.validate(this.form);

            const email = e.target.email.value.trim();
            const password = e.target.password.value;

            if(!isFormValid) return;

            this.setState({ isFormLoading: true });

            let memberRequest = {email, password};

            recaptchaService.getToken(paymentService.paymentType)
            .then(token => {
                memberRequest.recaptchaToken = token;
                memberRequest.requestType = 0;
                memberRequest.contactId = memberService.getMtcId();

                memberService.validateMember(memberRequest)
                .then(json => {
                    if (json.succeeded) {
                        paymentService.member = memberRequest;
                        this.props.history.push('/payment');
                    }
                    else {
                        this.setState({ isFormLoading: false, toastMessage: json.errors[0] });
                        formService.hideAllErrors(this.form);
                        gsap.to(this.toast, {
                            duration: 0.5,
                            visibility: 'visible',
                            opacity: 1,
                            y: -25,
                        })
                    }
                })         
            })
        }
    }

    exitToast() {
        gsap.to(this.toast, {
            duration: 0.3,
            opacity: 0,
            y: 25,
            onComplete: () => {
                gsap.set(this.toast, {
                    visibility: 'hidden'
                });
            },
        })
    }

    // Component
    render() {
        // Go To Pricing If No Payment Data
        if (!paymentService.item) {
            this.props.history.push(`/pricing/${bootstrapService.defaultPlanType}`);
        }

        const title = paymentService.item ? paymentService.item.title : null;
        const schedule = paymentService.schedule ? `${new Date(paymentService.schedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}, ${new Date(paymentService.schedule.startDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} - ${new Date(paymentService.schedule.endDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} (${scheduleService.getTimeZone()})` : null;

        const email = paymentService.member ? paymentService.member.email : "";
        const password = paymentService.member ? paymentService.member.password : "";

        return (
            <div className={styles['form-container']} ref={ref => this.container = ref}>
                <h1 className={styles['title']}>{title}</h1>
                {
                    schedule &&
                    <h3 className={styles['schedule']}>{schedule}</h3>
                }
                <form className={styles['form']} ref={ref => this.form = ref} onSubmit={this.submitForm}>
                    <Input className={styles['email-input']} type='email' name='email' placeholder='E-mail' defaultValue={email} required />
                    <Input className={styles['password-input']} type='password' name='password' placeholder='Password' defaultValue={password} required />
                    <Link to='/forgot-password' replace><span className={styles['forgot-button']} type='button'>Forgot password?</span></Link>
                    <PrimaryButton loading={this.state.isFormLoading} whiteSpinner={true} noAnimate={true} scale={1.02} className={styles['login-button']} type='submit' formNoValidate>Proceed to Payment</PrimaryButton>
                    <span className={styles['footnote']}>Your payment information will be collected on the next page</span>
                </form>
                <div className={styles['register-container']}>
                    <span className={styles['register-text']} onClick={this.fillSandboxForm}>Don’t have an account?</span>
                    <Link to='/buy-register' replace><span className={styles['register-button']}>Sign up</span></Link>
                </div>
                <Toast className={styles['toast']} error={true} title='Error' description={this.state.toastMessage} onExit={this.exitToast} ref={ref => this.toast = ref} />
            </div>
        )
    }
}

export default withRouter(BuyLogin);